<template>
    <div>
        <section class="my-0" :class="{'section': desktop}">
            <card class="border-0">
                <div class="row justify-content-md-center">
                    <div class="col-lg-6">
                        <card type="secondary" shadow
                              header-classes="bg-white pb-5"
                              body-classes="px-lg-5 py-lg-5"
                              class="border-0">
                            <template>
                                <!--<img v-lazy="'../img/svg/reply.svg'" />-->
                                <card id="request" :class="[{'input-group-alternative': true}, {'justify-content-center': true}]" v-if="requestor._id" style="justify-content:center; text-align:justify; color: white;" type="primary">
                                    <div class="px-lg-1 justify-content-center" style="text-align: left !important">
                                        <div>
                                            <span class="colText">
                                                <router-link style="font-weight: bold; color: white" :to="'/profile/' + requestor._id">
                                                    {{requestor.name.first}}
                                                </router-link>
                                            </span>
                                            <span>
                                                {{this.model.request}}
                                            </span>
                                        </div>
                                        <div v-if="document.feature.length > 0" class="mt-3">
                                            {{$t('reply.request.feature')}}:
                                            <span v-for="(feat, n) in document.feature" :key="n">
                                                {{feat}}{{n + 1 == document.feature.length ? '.' : ','}}
                                            </span>
                                        </div>
                                        <p v-if="document.detail" class="text-center mt-3" style="margin-bottom: 0px;">
                                            "{{document.detail}}"
                                        </p>
                                        <div v-if="requestor._id" style="font-weight: 700" class="mt-3">
                                            {{$t('reply.call', {client: requestor.name.first})}}
                                        </div>
                                        <div class="mt-3">
                                            <i id="small-letters">
                                                <span v-if="document.static">
                                                    {{$t('reply.request.warning.static')}}.
                                                </span>
                                                <span v-else>
                                                    {{$t('reply.request.warning.dynamic')}}.
                                                </span>
                                                {{$t('reply.request.warning.prefilled')}}.
                                            </i>
                                        </div>
                                    </div>
                                </card>
                                <base-alert class="mt-4" v-if="alert.new" type="warning">
                                    <span class="alert-inner--text">{{alert.message}}</span>
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alert.new = false">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </base-alert>

                                <form role="form">
                                    <div class="input-group-alternative mt-4">
                                        <label for="map">{{ $t('global.address') }}</label>
                                        <input id="map"
                                               atocomplete="no"
                                               :class="[{'form-control': true}, {'alert-border': alert}, {'input-group-alternative': true}]"
                                               @blur="wipeOnBlur($event);"
                                               ref="mapcomplete"
                                               placeholder="" />
                                        <b-collapse v-model="collapse">
                                            <div :class="[{'input-group-alternative': true}]" style="background-color: white">
                                                <table id="address" style="width: 100%; margin:0px;">
                                                    <tr>
                                                        <td><input :placeholder="$t('reply.sublabel.address.street')" class="form-control" style="border: 0px; background-color:white;" v-model="model.address.route" name="route" id="route" readonly></td>
                                                        <td><input :placeholder="$t('reply.sublabel.address.number')" class="form-control" style="border: 0px; background-color:white;" name="number" v-model="model.address.street_number" readonly></td>
                                                    </tr>
                                                    <tr>
                                                        <td><input :placeholder="$t('reply.sublabel.address.sublocality')" class="form-control" style="border: 0px; background-color:white;" name="sublocality" v-model="model.address.sublocality_level_1" readonly></td>
                                                        <td><input :placeholder="$t('reply.sublabel.address.city')" class="form-control" style="border: 0px; background-color:white;" name="administrative2" v-model="model.address.administrative_area_level_2" readonly></td>
                                                    </tr>
                                                    <tr>
                                                        <td><input :placeholder="$t('reply.sublabel.address.state')" class="form-control" style="border: 0px; background-color:white;" name="administrative1" v-model="model.address.administrative_area_level_1" readonly></td>
                                                        <td><input :placeholder="$t('reply.sublabel.address.zip')" class="form-control" style="border: 0px; background-color:white;" name="zip" v-model="model.address.postal_code" readonly></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </b-collapse>
                                    </div>

                                    <div class="mt-4 type input-group-alternative">
                                        <label for="propertyType">{{$t('reply.label.property')}}</label>
                                        <select v-model="model.property" id="propertyType" @change="getOptgroup($event)" class="propertySelection form-control" :disabled="disable('property')">
                                            <option value="any">{{$t('reply.sublabel.property.all.any')}}</option>
                                            <optgroup :label="$t('reply.sublabel.property.main.group')">
                                                <option value="apartment">{{$t('reply.sublabel.property.main.apartment')}}</option>
                                                <option value="house">{{$t('reply.sublabel.property.main.house')}}</option>
                                                <option value="office">{{$t('reply.sublabel.property.commercial.office')}}</option>
                                                <option value="store">{{$t('reply.sublabel.property.main.store')}}</option>
                                            </optgroup>
                                            <optgroup :label="$t('reply.sublabel.property.residential.group')">
                                                <option value="apartment">{{$t('reply.sublabel.property.residential.apartment')}}</option>
                                                <option value="condo">{{$t('reply.sublabel.property.residential.condo')}}</option>
                                                <option value="house">{{$t('reply.sublabel.property.residential.house')}}</option>
                                                <option value="penthouse">{{$t('reply.sublabel.property.residential.penthouse')}}</option>
                                                <option value="kitchenette">{{$t('reply.sublabel.property.residential.kitchenette')}}</option>
                                                <option value="loft">{{$t('reply.sublabel.property.residential.loft')}}</option>
                                                <option value="studio">{{$t('reply.sublabel.property.residential.studio')}}</option>
                                                <option value="farm">{{$t('reply.sublabel.property.residential.farm')}}</option>
                                                <option value="ranch">{{$t('reply.sublabel.property.residential.ranch')}}</option>
                                            </optgroup>
                                            <optgroup :label="$t('reply.sublabel.property.commercial.group')">
                                                <option value="office">{{$t('reply.sublabel.property.commercial.office')}}</option>
                                                <option value="shed">{{$t('reply.sublabel.property.commercial.shed')}}</option>
                                                <option value="garage">{{$t('reply.sublabel.property.commercial.garage')}}</option>
                                                <option value="warehouse">{{$t('reply.sublabel.property.commercial.warehouse')}}</option>
                                                <option value="store">{{$t('reply.sublabel.property.commercial.store')}}</option>
                                                <option value="studio">{{$t('reply.sublabel.property.commercial.studio')}}</option>
                                                <option v-if="document.type == 'buy'" value="land">{{$t('reply.sublabel.property.residential.land')}}</option>
                                            </optgroup>
                                        </select>
                                    </div>

                                    <div v-if="document.static" class="mt-4 input-group-alternative">
                                        <label>{{$t('reply.label.price')}}</label>
                                        <base-input alternative
                                                    @change="calculate"
                                                    v-currency="{currency: 'BRL', valueAsInteger: true, locale: 'pt', precision: 0, distractionFree: {hideCurrencySymbol: false,}, valueRange: {max: document.amount[1]}, allowNegative: false}"
                                                    v-model="model.amount"
                                                    :placeholder="$t('global.currency.real')"
                                                    ref="amount">
                                        </base-input>
                                    </div>

                                    <div v-else class="mt-4 input-group-alternative">
                                        <label>{{$t('reply.label.price')}}</label>
                                        <base-input alternative
                                                    @change="calculate"
                                                    v-model="model.amount"
                                                    v-currency="{currency: 'BRL', valueAsInteger: true, locale: 'pt', precision: 0, distractionFree: {hideCurrencySymbol: false,}, allowNegative: false}"
                                                    :placeholder="$t('global.currency.real')"
                                                    ref="amount">
                                        </base-input>
                                    </div>

                                    <div class="mt-4 input-group-alternative">
                                        <label>{{$t('reply.label.condominium')}}</label>
                                        <base-input alternative
                                                    @change="calculate"
                                                    v-currency="{currency: 'BRL', valueAsInteger: true, locale: 'pt', precision: 0, distractionFree: {hideCurrencySymbol: false,}, allowNegative: false}"
                                                    v-model="model.condominium"
                                                    :step="100"
                                                    :min="0"
                                                    ref="condominium"
                                                    :placeholder="$t('global.currency.real')">
                                        </base-input>
                                    </div>

                                    <div v-if="document.type == 'rent'" class="mt-4 input-group-alternative">
                                        <label>{{$t('reply.label.total')}}</label>
                                        <base-input alternative
                                                    v-model="model.total"
                                                    :max="document.max_price"
                                                    :isReadonly="true"
                                                    :placeholder="$t('global.currency.real')">
                                        </base-input>
                                    </div>

                                    <div v-if="document.static" class="mt-4 input-group-alternative">
                                        <label>{{$t('reply.label.area')}}</label>
                                        <base-input alternative
                                                    :placeholder="$t('global.meter')"
                                                    v-model="model.area"
                                                    type="number"
                                                    :min="document.area[0]">
                                        </base-input>
                                    </div>
                                    <div v-else class="mt-4 input-group-alternative">
                                        <label>{{$t('reply.label.area')}}</label>
                                        <base-input alternative
                                                    :placeholder="$t('global.meter')"
                                                    v-model="model.area"
                                                    type="number"
                                                    :min="15">
                                        </base-input>
                                    </div>

                                    <div v-if="document.type == 'rent'">
                                        <div class="mt-5" v-if="document.static">
                                            <!--<div>
                                                <vue-slider :tooltip-formatter="'{value} ' + $tc('reply.tooltip.month', model.rent_time)"
                                                            tooltip="always"
                                                            v-model="model.rent_time"
                                                            :marks="true"
                                                            :hide-label="true"
                                                            :min="1"
                                                            :max="12">
                                                </vue-slider>
                                                <label>{{$t('reply.label.time')}}</label>
                                            </div>-->

                                            <div class="mt-4" id="furnished">
                                                <base-checkbox v-model="model.furnished" disabled>
                                                    <label>{{$t('reply.label.furnished')}}</label>
                                                </base-checkbox>
                                            </div>
                                        </div>

                                        <div v-else>
                                            <!--<div class="mt-5">
                                                <vue-slider :tooltip-formatter="'{value} ' + $tc('reply.tooltip.month', model.rent_time)"
                                                            tooltip="always"
                                                            v-model="model.rent_time"
                                                            :marks="true"
                                                            :hide-label="true"
                                                            :min="1"
                                                            :max="12">
                                                </vue-slider>
                                                <label>{{$t('reply.label.time')}}</label>
                                            </div>-->

                                            <div class="mt-4" id="furnished">
                                                <base-checkbox v-model="model.furnished">
                                                    <label>{{$t('reply.label.furnished')}}</label>
                                                </base-checkbox>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-4" v-if="!['0', 0, null].includes(document.pet)">
                                        <label>{{$t('reply.label.pet')}}</label>
                                        <b-form-group>
                                            <b-form-radio-group v-model="model.pet">
                                                <b-form-radio value="1" :disabled="document.static && document.pet == '2'">{{$t('reply.sublabel.pet.yes')}}</b-form-radio>
                                                <b-form-radio value="2" :disabled="document.static && document.pet == '1'">{{$t('reply.sublabel.pet.no')}}</b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </div>

                                    <div v-if="document.static">
                                        <div class="mt-4 input-group-alternative">
                                            <label>{{$t('reply.label.suite')}}</label>
                                            <base-input alternative
                                                        v-model="model.suite"
                                                        type="number"
                                                        :min="document.suite"
                                                        :disabled="disable()">
                                            </base-input>
                                        </div>

                                        <div class="mt-4 input-group-alternative">
                                            <label>{{$t('reply.label.room')}}</label>
                                            <base-input alternative
                                                        v-model="model.room"
                                                        type="number"
                                                        :min="document.room"
                                                        :disabled="disable()">
                                            </base-input>
                                        </div>

                                        <div class="mt-4 input-group-alternative">
                                            <label>{{$t('reply.label.garage')}}</label>
                                            <base-input alternative
                                                        v-model="model.garage"
                                                        type="number"
                                                        :min="document.garage"
                                                        :disabled="disable()">
                                            </base-input>
                                        </div>

                                        <!--<div class="mt-4 input-group-alternative">
                                            <label>{{$t('reply.label.floor')}}</label>
                                            <base-input alternative
                                                        v-model="model.floor"
                                                        type="number"
                                                        :min="document.floor"
                                                        :disabled="disable()">
                                            </base-input>
                                        </div>-->
                                    </div>
                                    <div v-else>
                                        <div class="mt-4 input-group-alternative">
                                            <label>{{$t('reply.label.suite')}}</label>
                                            <base-input alternative
                                                        v-model="model.suite"
                                                        type="number"
                                                        :min="0"
                                                        :disabled="disable()">
                                            </base-input>
                                        </div>

                                        <div class="mt-4 input-group-alternative">
                                            <label>{{$t('reply.label.room')}}</label>
                                            <base-input alternative
                                                        v-model="model.room"
                                                        type="number"
                                                        :min="0"
                                                        :disabled="disable()">
                                            </base-input>
                                        </div>

                                        <div class="mt-4 input-group-alternative">
                                            <label>{{$t('reply.label.garage')}}</label>
                                            <base-input alternative
                                                        v-model="model.garage"
                                                        type="number"
                                                        :min="0"
                                                        :disabled="disable()">
                                            </base-input>
                                        </div>

                                        <!--<div class="mt-4 input-group-alternative">
                                            <label>{{$t('reply.label.floor')}}</label>
                                            <base-input alternative
                                                        v-model="model.floor"
                                                        type="number"
                                                        :min="1"
                                                        :disabled="disable()">
                                            </base-input>
                                        </div>-->
                                    </div>

                                    <div class="mt-4 input-group-alternative">
                                        <label>{{$t('reply.label.feature')}}</label>
                                        <b-form-tags v-model="model.feature"
                                                     no-outer-focus
                                                     tag-pills
                                                     placeholder=""
                                                     addButtonText="+"
                                                     addButtonVariant="primary"
                                                     addOnChange
                                                     separator=",;."
                                                     tagVariant="primary"
                                                     size="lg"
                                                     v-b-popover.hover.bottomright="$t('reply.label.tag')"
                                                     duplicate-tag-text=""
                                                     :defaultValue="document.static ? document.feature : []">
                                        </b-form-tags>
                                    </div>

                                    <div :class="[{'input-group-alternative': !model.availability}]">
                                        <base-checkbox class="mt-4" v-model="model.availability">
                                            <label>{{$t('reply.label.availability')}}</label>
                                        </base-checkbox>

                                        <div v-if="!model.availability">
                                            <base-input class="mt-3" :label="$t('reply.label.available')">
                                                <flat-picker slot-scope="{focus, blur}"
                                                             @on-open="focus"
                                                             @on-close="blur"
                                                             :config="config1"
                                                             class="form-control datepicker"
                                                             v-model="model.available_date">
                                                </flat-picker>
                                            </base-input>
                                        </div>
                                    </div>

                                    <div v-if="advanced" class="mt-4 type input-group-alternative">
                                        <label form="visit">{{$t('reply.label.visit')}}</label>
                                        <b-form-select v-model="model.visit" id="visit" class="form-control">
                                            <option value="limitedBusinessTime">{{$t('reply.sublabel.visit.limited')}}</option>
                                            <option value="allBusinessTime">{{$t('reply.sublabel.visit.all')}}</option>
                                            <option value="specific">{{$t('reply.sublabel.visit.specific')}}</option>
                                            <option value="onlineTour">{{$t('reply.sublabel.visit.online')}}</option>
                                        </b-form-select>

                                        <div v-if="model.visit == 'specific'">
                                            <div class="mt-4">
                                                <div class="input-group-prepend">
                                                    <flat-picker :config="config2"
                                                                 class="form-control datepicker"
                                                                 name="date"
                                                                 v-model="model.visit_dates.datetime_0">
                                                    </flat-picker>
                                                    <base-button type="primary" @click="manageDatetime('add')">+</base-button>
                                                </div>
                                            </div>
                                            <div class="mt-1" v-for="n in datetimes" :key="n">
                                                <div class="input-group-prepend" style="margin: 0px !important">
                                                    <flat-picker v-model="model.visit_dates['datetime_' + n.toString()]"
                                                                 :config="config2"
                                                                 class="form-control datepicker"
                                                                 name="date">
                                                    </flat-picker>
                                                    <base-button type="danger" v-if="n == datetimes" @click="manageDatetime('remove')">-</base-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="advanced">
                                        <div class="mt-4 type input-group-alternative">
                                            <label form="visit">{{$t('reply.label.tax')}}</label>
                                            <base-input alternative type="number" v-model="model.tax" step="50" :min="0"></base-input>
                                        </div>

                                        <base-input class="mt-4 input-group-alternative" v-for="text_detail in text_details" :key="text_detail[0]">
                                            <label>{{titleCondition(text_detail[1])}}</label>
                                            <textarea class="form-control form-control-alternative" rows="3" v-model="model[text_detail[0]]"></textarea>
                                        </base-input>
                                    </div>

                                    <base-input class="mt-4 input-group-alternative">
                                        <label>{{$t('reply.label.detail')}}</label>
                                        <textarea class="form-control form-control-alternative" rows="3" v-model="model.detail"></textarea>
                                    </base-input>

                                    <div class="mt-4 input-group-alternative" type="secondary">
                                        <label>{{ $t('reply.label.images') }}</label>
                                        <vue-upload-multiple-image @upload-success="uploadImageSuccess"
                                                                   :dataImages="model.images"
                                                                   :showPrimary="false"
                                                                   browseText=""
                                                                   :maxImage="10"
                                                                   @before-remove="beforeRemove"></vue-upload-multiple-image>
                                    </div>

                                    <base-button class="mt-4" type="primary" block @click="submitModel()">{{$t('reply.label.submit')}}</base-button>

                                </form>
                            </template>
                        </card>
                    </div>
                </div>
                <div class="vld-parent">
                    <loading :active.sync="isLoading"
                             :can-cancel="false"
                             :is-full-page="true"
                             color="#5271ff"></loading>
                </div>
            </card>
        </section>
        <modal :show.sync="register" style="text-align: justify; z-index: 9999999999999999999999" body-classes="p-0">
            <template slot="header">
                <h5 class="modal-title" style="text-align: left !important"><strong>{{$t('reply.register', {client: requestor.name.first})}}.</strong></h5>
            </template>
            <!--<h3 slot="header" class="modal-title text-left" id="modal-title-default">{{$t('policy.title')}}</h3>-->
            <register-card source="modal"
                           type="realtor"
                           :showType="false"
                           :login="true"
                           @loggedIn="submitModel"></register-card>
            <!--<template slot="footer">
            <base-button type="link" class="ml-auto" @click="policy = false;">
                Fechar
            </base-button>
            </template>-->
        </modal>
    </div>
</template>

<script>
    //import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/antd.css';
    import gmapsInit from '../utils/gmaps';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import VueUploadMultipleImage from 'vue-upload-multiple-image'
    import { BFormGroup, BFormRadio, BFormRadioGroup, BFormSelect, BCollapse } from 'bootstrap-vue'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import $ from 'jquery';
    import { CurrencyDirective } from 'vue-currency-input'
    import RegisterCard from '../components/RegisterCard';

    export default {
        name: 'Reply',
        components: {
            flatPicker,
            //VueSlider,
            VueUploadMultipleImage,
            BFormRadio,
            BFormGroup,
            BFormRadioGroup,
            Loading,
            BFormSelect,
            BCollapse,
            RegisterCard,
        },
        directives: {
            currency: CurrencyDirective
        },
        data() {
            return {
                config1: {
                    altFormat: 'M j, Y',
                    altInput: true,
                    allowInput: true,
                    dateFormat: 'Y-m-d',
                },
                config2: {
                    wrap: true, // set wrap to true only when using 'input-group'
                    altFormat: 'M j, Y',
                    altInput: true,
                    allowInput: true,
                    dateFormat: 'Y-m-d',
                },
                requestor: {
                    name: {
                    }
                },
                document: {
                },
                model: {
                    address: {
                        route: '',
                        street_number: '',
                        sublocality_level_1: '',
                        administrative_area_level_2: '',
                        administrative_area_level_1: '',
                        postal_code: '',
                    },
                    place: '',
                    condominium: '',
                    total: '',
                    area: '',
                    amount: '',
                    availability: true,
                    available_date: '',
                    visit: "limitedBusinessTime",
                    visit_dates: {},
                    tax: '',
                    document: '',
                    condition: '',
                    special: '',
                    detail: '',
                    images: [],
                    to: {},
                    request: '',
                    currency: 'BRL' //Hard code, to be removed.
                },
                comma: false,
                alert: {
                    new: false,
                    message: '',
                    type: 'warning',
                },
                isLoading: false,
                filters: [/^[a-zA-Z\s]*$/],
                datetimes: 0,
                text_details: [
                    ['document', this.$t('reply.label.document')],
                    ['condition', ''],
                    ['special', this.$t('reply.label.special')],
                ],
                submitTry: 1,
                collapse: false,
                desktop: screen.width > 700,
                register: false,
                advanced: false,
            }
        },

        watch: {
            register() {
                if(!this.register) { document.querySelector("body").classList.remove("modal-open"); }
            }
        },

        methods: {
            async resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
                return new Promise((resolve) => {
                    let img = new Image()
                    img.src = base64Str
                    img.onload = () => {
                        let canvas = document.createElement('canvas')
                        const MAX_WIDTH = maxWidth
                        const MAX_HEIGHT = maxHeight
                        let width = img.width
                        let height = img.height

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width
                                width = MAX_WIDTH
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height
                                height = MAX_HEIGHT
                            }
                        }
                        canvas.width = width
                        canvas.height = height
                        let ctx = canvas.getContext('2d')
                        ctx.drawImage(img, 0, 0, width, height)
                        resolve(canvas.toDataURL())
                    }
                })
            },

            requestText() {
                var price_type = this.$t('reply.request.price.' + this.document.price_type)
                var text = this.$t('reply.request.want')
                    + this.$t('reply.request.type.' + this.document.type)
                    + (this.document.property != 'any' ? this.$t('reply.request.article') : this.$t('reply.request.any'))
                    + this.$t('reply.request.property.' + this.document.property)
                    //+ (this.document.type != 'buy' ? this.$t('reply.request.time', { time: String(this.document.rent_time) }) : '')
                    //+ (this.document.type != 'buy' ? this.$tc('reply.request.month', this.document.rent_time) : '')
                    + this.$t('reply.request.in')
                    + this.document.location
                    + (this.document.amount[0] > 0 ?                         
                        this.$t('reply.request.with', {
                            price: price_type,
                            minamount: ('' + this.document.amount[0]).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                            maxamount: ('' + this.document.amount[1]).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                            minarea: this.document.area[0],
                            maxarea: this.document.area[1]
                        }) :
                        this.$t('reply.request.without', {
                            price: price_type,
                            maxamount: ('' + this.document.amount[1]).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                            minarea: this.document.area[0],
                            maxarea: this.document.area[1]
                        })
                    )
                    + (this.document.furnished ? this.$tc('reply.request.furnished') : '')
                    //+ this.$tc('reply.request.floor', this.document.floor, { quantity: this.document.floor })
                    + this.$tc('reply.request.suite', this.document.suite, { quantity: this.document.suite })
                    + this.$tc('reply.request.room', this.document.room, { quantity: this.document.room })
                    + this.$tc('reply.request.garage', this.document.garage, { quantity: this.document.garage })
                    + this.$tc('reply.request.pet', this.document.pet, { quantity: this.document.pet })
                    + '.'

                for (var key in ['floor', 'suite', 'room', 'garage', 'pet']) {
                    if (this.document[key] != '0') {
                        return text.replace(/,([^,]*)$/, ' ' + this.$t('reply.request.connector') + '$1');
                    }
                }

                return text;
            },

            switchLoading() {
                this.isLoading = !this.isLoading;
            },

            beforeRemove(index, done, fileList) {
                index; fileList //To quiet web-pack complaints.
                done()
            },
            async uploadImageSuccess(formData, index, fileList) {
                for (let i = 0; i < fileList.length; i++) {
                    const resized = await this.resizeImage(fileList[i].path, 800, 600);
                    fileList[i].path = resized;
                }
                this.model.images = fileList;
            },

            updateModel(document) {
                this.document = document;

                this.model = { ...document, ...this.model };
                //this.model.to = document.from;
                this.model.request_id = document._id;

                //if (this.document.floor == 0) {
                //    this.model.floor = 1;
                //}

                this.model.request = this.requestText();
            },

            updateAgents(requestor) {
                this.requestor = requestor;
                this.model.to = {
                    name: requestor.name.first,
                    type: requestor.type,
                    _id: requestor._id
                }
            },

            updateInput(key, value) {
                this.model[key] = value;
            },
            updateAddress(key, value) {
                this.model['address'][key] = value;
            },
            getInput(key) {
                return this.model[key]
            },
            wipeOnBlur(event) {
                event.target.value = '';
            },
            calculate() {
                if (this.document.type == 'rent') {
                    var amount = parseInt(this.model.amount.slice(3).split('.').join(''));
                    var condominium = parseInt(this.model.condominium.slice(3).split('.').join(''));

                    if (isNaN(amount)) {
                        amount = 0;
                    }

                    if (isNaN(condominium)) {
                        condominium = 0;
                    }

                    this.model.total = this.$t('global.currency.real') + ' ' + (amount + condominium).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                }
            },
            ranger(value) {
                var tag = ' months'
                if (value == 1) {
                    tag = ' month'
                } else if (value == 12) {
                    tag = ' year'
                    value = 1
                }
                return value + tag;
            },
            disable(tag = null) {
                if (this.document.property != 'any' && this.document.static && tag == 'property') {
                    return true
                } else if (tag == 'pet') {
                    return (['house', 'condo', 'land', 'farm', 'ranch'].includes(this.model.property) || [this.$t('request.sublabel.property.commercial.group')].includes(this.model.property_group));
                } else if (tag == null) {
                    return (['land'].includes(this.model.property) || [this.$t('reply.sublabel.property.commercial.group')].includes(this.model.property_group));
                }
            },
            manageDatetime(command) {
                if (command == 'add') {
                    this.datetimes += 1;
                }
                if (command == 'remove' && this.datetimes > 0) {
                    this.datetimes -= 1;
                }
            },

            titleCondition(title) {
                if (title == '') {
                    var type = this.$t('reply.' + this.document.type);
                    return this.$t('reply.label.condition', { type: type });
                }

                return title
            },

            pushAlert(message, type = null, from_server = null, route = null) {
                if (from_server) {
                    this.alert.message = this.$t(message);
                } else {
                    this.alert.message = message;
                }

                if (type == undefined) {
                    type = 'warning';
                }

                if (route) {
                    this.store.state.alert.message = message;
                    this.store.state.alert.new = true;
                    this.store.state.alert.type = type;
                    this.router.push(route);
                } else {
                    this.alert.new = true;
                    this.alert.type;
                    this.$scrollTo('#request');
                }
            },

            async submitModel() {
                var pushAlert = this.pushAlert;
                var router = this.$router;
                var message;
                var alert;
                var checkResponse;
                var submitTry = this.submitTry;
                var gtag = this.$gtag;

                var dynamicCheck = [
                    { condition: this.model.place == '', message: this.$t('warning.reply.address') },
                    { condition: this.model.property == 'any', message: this.$t('warning.reply.property') },
                    { condition: this.model.amount == '', message: this.$t('warning.reply.amount') },
                    { condition: this.model.area == '', message: this.$t('warning.reply.area') },
                    { condition: (this.model.suite == '' || this.model.room == '' || this.model.garage == '' || this.model.floor == ''), message: this.$t('warning.reply.general') },
                    { condition: !this.model.availability && this.model.available_date == '', message: this.$t('warning.reply.available') },
                    { condition: this.model.visit == 'specific' && !('datetime_0' in this.model.visit_dates), message: this.$t('warning.reply.date') },
                    //{ condition: this.model.document == '', message: this.$t('warning.reply.document') }, Makes the Document field be mandatory to fill.
                ]

                function checkup(array) {
                    var message;
                    var alert = false;
                    var response = [alert, message];
                    for (let i = 0; i < array.length; i++) {
                        var check = array[i];
                        if (check.condition) {
                            message = check.message;
                            alert = true;
                            response = [alert, message];
                            break
                        }
                    }
                    return response;
                }

                checkResponse = checkup(dynamicCheck);
                alert = checkResponse[0];
                message = checkResponse[1];

                if (!alert && this.document.static) {
                    var continueFlow = true;

                    if (parseInt(this.model.total.slice(3).split('.').join('')) > parseInt(this.document.amount[1])) {
                        message = this.$t('warning.reply.price.' + this.document.price_type, { price: this.document.amount[1].toString() });
                        alert = true;
                        continueFlow = false;
                    }

                    if (continueFlow) {
                        var staticCheck = [
                            { condition: parseInt(this.model.rent_time) > parseInt(this.document.rent_time), message: this.$t('warning.reply.time', { time: this.document.rent_time.toString() }) },
                            { condition: parseInt(this.model.area) < this.document.area[0], message: this.$t('warning.reply.area', { area: this.document.area[0].toString() }) },
                            { condition: parseInt(this.model.suite) < parseInt(this.document.suite), message: this.$t('warning.reply.suite', { suite: this.document.suite.toString() }) },
                            { condition: parseInt(this.model.room) < parseInt(this.document.room), message: this.$t('warning.reply.room', { room: this.document.room.toString() }) },
                            { condition: parseInt(this.model.garage) < parseInt(this.document.garage), message: this.$t('warning.reply.garage', { garage: this.document.garage.toString() }) },
                            //{ condition: parseInt(this.model.floor) < parseInt(this.document.floor), message: this.$t('warning.reply.floor', { floor: this.document.floor.toString() }) },
                        ];

                        checkResponse = checkup(staticCheck);
                        alert = checkResponse[0];
                        message = checkResponse[1];
                    }
                }

                gtag.config('UA-158667515-1', {
                    'custom_map': {
                        'dimension1': 'try_number',
                        'dimension2': 'try_success',
                        'dimension3': 'user_type',
                    }
                });
                if (alert == true) {
                    gtag.event('reply_try', { 'try_number': submitTry, 'try_success': false })
                    submitTry++;
                    pushAlert(message);
                } else {
                    if (this.$store.state.logged_in) {
                        this.register = false;
                        var switchLoading = this.switchLoading;
                        switchLoading();
                        $.ajax({
                            url: 'https://api.immobl.com/reply',
                            contentType: 'application/json;charset=UTF-8',
                            xhrFields: {
                                withCredentials: true
                            },
                            crossDomain: true,
                            type: 'POST',
                            data: JSON.stringify({ data: this.model }),
                            success: function (response) {
                                switchLoading()

                                if (response.success) {
                                    gtag.event('reply', {
                                        'event_callback': function () {
                                            router.push('/dashboard');
                                        }
                                    });
                                    //pushAlert(response.message, 'success', true);
                                } else {
                                    gtag.event('reply_try', { 'try_number': submitTry, 'try_success': false })
                                    submitTry++;
                                    pushAlert(response.message);
                                }
                            },
                            //error: function (err) {
                            //    //console.log(err);
                            //    switchLoading()
                            //}
                        });
                    } else {
                        this.register = true;
                        gtag.event('see_register_card');
                    }
                }
            },
            getOptgroup: function (event) {
                const index = event.target.selectedIndex;
                const option = event.target.options[index];
                const optgroup = option.parentElement;
                const label = optgroup.getAttribute('label');
                this.model.property_group = label;
            },
        },

        async mounted() {
            document.querySelector("body").classList.remove("modal-open");

            if (this.$store.state.logged_in) {
                this.$gtag.set({ 'user_id': this.$store.state._id });
            }

            const google = await gmapsInit();
            const request_id = this.$route.params.request_id;
            var updateModel = this.updateModel;
            var updateAgents = this.updateAgents;
            var router = this.$router;

            await $.ajax({
                url: 'https://api.immobl.com/request/' + request_id,
                contentType: 'application/json;charset=UTF-8',
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                type: 'GET',
                success: function (response) {
                    if (response.success) {
                        updateModel(response.document);
                    } else {
                        router.push('/dashboard');
                    }
                },
                //error: function (err) {
                //    //console.log(err);
                //}
            });

            $.ajax({
                url: 'https://api.immobl.com/' + 'profile/' + this.document.from,
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                success: function (response) {
                    if (response.success) {
                        updateAgents(response.user);
                    }
                },
                //error: function (err) {
                //    //console.log(err);
                //}
            });

            const input = this.$refs.mapcomplete
            const autocomplete = new google.maps.places.Autocomplete(
                input, { types: ['address'], componentRestrictions: { country: this.$store.state.country.iso2 } });

            autocomplete.setFields(['address_component', 'geometry']);

            var updateInput = this.updateInput;
            var updateAddress = this.updateAddress;
            var getInput = this.getInput;
            var isStatic = getInput('static');
            var placeTypes = this.model.address_components[0].types;
            var longName = this.model.address_components[0]['long_name'];
            var data = this.$data;

            autocomplete.addListener('place_changed', function () {
                var addressTypes
                var rightPlace
                var componentForm = {
                    street_number: 'short_name',
                    route: 'long_name',
                    sublocality_level_1: 'long_name',
                    administrative_area_level_2: 'long_name',
                    administrative_area_level_1: 'short_name',
                    postal_code: 'short_name'
                }
                const place = autocomplete.getPlace();

                if (!place.geometry) {
                    input.value = '';
                } else {
                    if (isStatic) {
                        rightPlace = false;
                        for (let i = 0; i < place.address_components.length; i++) {
                            addressTypes = place.address_components[i].types
                            if (addressTypes.some(r => placeTypes.includes(r)) && place.address_components[i]['long_name'] == longName) {
                                rightPlace = true;
                            }
                        }
                    } else {
                        rightPlace = true;
                    }

                    if (rightPlace) {
                        updateInput('place', place.geometry.location.toString());
                        updateInput('location', input.value);

                        input.placeholder = input.value;
                        input.value = '';
                        input.classList.add('autocompletePlaceholder');
                        data.collapse = true;

                        for (var component in componentForm) {
                            updateAddress(component, '');
                        }

                        for (let i = 0; i < place.address_components.length; i++) {
                            addressTypes = place.address_components[i].types;

                            for (let j = 0; j < addressTypes.length; j++) {
                                var addressType = addressTypes[j]
                                if (componentForm[addressType]) {
                                    var val = place.address_components[i][componentForm[addressType]];
                                    updateAddress(addressType, val);
                                }
                            }
                        }
                    } else {
                        input.value = '';
                    }
                }
            });
        },

    };
</script>

<style scoped>
    .autocompletePlaceholder::-webkit-input-placeholder {
        color: #8898aa
    }

    div /deep/ .pac-container {
        z-index: 9999999999999999999999999999999999 !important;
        top: 47px !important;
        left: 0 !important;
    }

    /*#map::placeholder {
        color: black;
        opacity: 1;
    }

    #map::-ms-input-placeholder {
        color: black;
    }

    #map::-ms-input-placeholder {
        color: black;
    }*/

    .colText {
        text-align: left;
    }

    .Gmap {
        width: 65%;
        height: 650px;
        float: left;
        /*bottom: 0;*/
        position: absolute;
    }

    .properties {
        width: 35%;
        float: right;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 650px;
        bottom: 0;
        position: relative;
        margin-bottom: 0px;
    }

    .form {
        width: 50%;
        margin-left: 6px;
    }

    .input-group {
        border: 0px;
    }

    input {
        min-width: 100%;
        max-width: 100%;
    }

    /*img {
        max-width: 70px;
        float: left;
    }*/

    #small-letters {
        font-size: 12px;
        line-height: 1px;
    }

    section {
        overflow-x: hidden;
    }

    .autocompletePlaceholder::-webkit-input-placeholder {
        color: #8898aa
    }

    label {
        font-weight: bold !important;
    }
</style>